<template>
  <v-card color="grey lighten-4">
    <v-toolbar color="accent" dark dense>
      <v-toolbar-title data-test="header-text"
        >DUT Details: {{ dut.dut_name }}</v-toolbar-title
      >
    </v-toolbar>
    <v-card-text class="title">
      <v-container fluid>
        <v-row align="center">
          <v-col cols="12">
            <v-row dense class="mt-5">
              <v-col cols="12">
                <span>
                  <span>W</span>
                  <v-chip
                    outlined
                    label
                    color="primary"
                    class="mx-2"
                    data-test="pcb_width_chip"
                    >{{ dut.pcb_width }}</v-chip
                  >
                  <span class="body-2">mm</span>
                  <span class="mx-5 font-weight-bold">x</span>
                  <span class="ml-2">H</span>
                  <v-chip
                    outlined
                    label
                    color="primary"
                    class="mx-2"
                    data-test="pcb_height_chip"
                    >{{ dut.pcb_height }}</v-chip
                  >
                  <span class="body-2">mm</span>
                </span>
                <span class="mx-5 font-weight-bold">x</span>
                <span class="ml-2 mr-5">
                  <span>PCB Thickness</span>
                  <v-chip
                    outlined
                    label
                    color="primary"
                    class="mx-2"
                    data-test="pcb_thickness_chip"
                    >{{ dut.pcb_thickness }}</v-chip
                  >
                </span>
                <BaseBadgeCounter
                  v-if="dutConfigs && tpCount"
                  :itemCount="tpCount"
                  :useIcon="false"
                  :leftMargin="0"
                  color="primary"
                  viewIcon="TPs"
                  tooltipText="Number of Test Points"
                />
                <BaseBadgeCounter
                  v-if="dutConfigs && gpCount"
                  :itemCount="gpCount"
                  :useIcon="false"
                  :leftMargin="5"
                  color="primary"
                  viewIcon="GPs"
                  tooltipText="Number of Guide Pins"
                />
                <BaseBadgeCounter
                  v-if="dutConfigs && ppCount"
                  :itemCount="ppCount"
                  :useIcon="false"
                  :leftMargin="5"
                  color="primary"
                  viewIcon="PPs"
                  tooltipText="Number of Pressure Pins"
                />
              </v-col>
            </v-row>
            <v-row dense class="mt-2">
              <v-col cols="12" md="6">
                <v-list-item three-line class="ml-n4">
                  <v-list-item-content>
                    <v-list-item-subtitle class="title mt-2">
                      <v-row dense>
                        <v-col cols="12" md="5">
                          Flex Board:
                        </v-col>
                        <v-col cols="12" md="2">
                          <v-chip
                            outlined
                            label
                            color="primary"
                            data-test="flex_board_chip"
                            >{{ dut.flex_board ? "Yes" : "No" }}</v-chip
                          >
                        </v-col>
                      </v-row>
                    </v-list-item-subtitle>
                    <v-list-item-subtitle class="title mt-2">
                      <v-row dense>
                        <v-col cols="12" md="5">
                          Dual Sided Probing:
                        </v-col>
                        <v-col cols="12" md="2">
                          <v-chip
                            outlined
                            label
                            color="primary"
                            data-test="dual_sided_chip"
                            class="mb-2"
                            >{{ dut.dual_sided_probing ? "Yes" : "No" }}</v-chip
                          >
                        </v-col>
                      </v-row>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
            </v-row>
            <v-row dense class="mt-5">
              <v-col cols="12">
                <span>
                  <v-textarea
                    label="Additional Comments"
                    rows="4"
                    readonly
                    auto-grow
                    outlined
                    flat
                    background-color="grey lighten-4"
                    :value="
                      dut.additional_comments
                        ? dut.additional_comments
                        : 'Not indicated'
                    "
                    data-test="comments-text"
                /></span>
              </v-col>
            </v-row>
            <v-row dense class="mt-5">
              <v-col
                cols="12"
                lg="4"
                sm="6"
                v-for="(file, index) in dut.design_files"
                :key="index"
                class="mb-5"
              >
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <span class="ma-0" v-on="on">
                      <v-btn
                        block
                        x-large
                        color="secondary"
                        :disabled="!file.url"
                        @click="
                          onDownloadFile({
                            url: file.url,
                            filename: `${quoteName} - ${
                              dut.dut_name
                            } - ${file.url.split('?')[0].split('/').pop()}`,
                          })
                        "
                        class="ma-0"
                        :data-test="file.name"
                        >{{ file.name
                        }}<v-icon right>
                          mdi-cloud-download
                        </v-icon></v-btn
                      ></span
                    >
                  </template>
                  <span>{{
                    file.url
                      ? file.url.split("?")[0].split("/").pop()
                      : "No files to download"
                  }}</span>
                </v-tooltip>
              </v-col>
              <v-col v-if="!calledFromConfigure" cols="12" lg="4" sm="6" class="mb-5">
                <v-btn
                  block
                  x-large
                  color="secondary"
                  @click="gotoFixtureDesignTable({ projectId: projectId, dutId: dut.dut_id })"
                  >View Fixture Design<v-icon small class="ml-1">
                    mdi-open-in-new
                  </v-icon></v-btn
                >
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>
<script>
export default {
  name: "DutDetailsCard",
  props: {
    dut: {
      type: Object,
      default: null,
    },
    quoteName: {
      type: String,
      default: null,
    },
    dutConfigs: {
      type: Object,
      default: null,
    },
    projectId: {
      type: Number,
      default: null,
    },
    calledFromConfigure: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    tpCount() {
      return this.dutConfigs?.testpoints
        ? this.dutConfigs.testpoints.length
        : null;
    },
    gpCount() {
      return this.dutConfigs?.guide_pins
        ? this.dutConfigs.guide_pins.length
        : null;
    },
    ppCount() {
      return this.dutConfigs?.pressure_pins
        ? this.dutConfigs.pressure_pins.length
        : null;
    },
  },
  methods: {
    onDownloadFile(payload) {
      this.$emit("downloadFile", payload);
    },
    gotoFixtureDesignTable(payload) {
      this.$emit("onGoToFixtureDesignTable", payload);
    },
  },
};
</script>
